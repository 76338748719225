/* eslint-disable @typescript-eslint/no-shadow */
export enum ROLE_STATUS {
  INACTIVE = 0,
  ACTIVE = 1,
}

export enum ROLE_SHORT_CODE {
  SUPER_ADMIN = "SA",
  SUPER_EMPLOYEE = "SE",
  OEM_ADMIN = "OEMA",
  OEM_EMPLOYEE = "OEME",
  DRIVER = "DR",
  EV_OWNER = "EVO",
  FLEET_ADMIN = "FA",
  FLEET_EMPLOYEE = "FE",
  DEALER_ADMIN = "DA",
  DEALER_EMPLOYEE = "DE",
  BMS_ADMIN = "BMSA",
  BMS_EMPLOYEE = "BMSE",
  MCU_ADMIN = "MCUA",
  MCU_EMPLOYEE = "MCUE",
  VCU_ADMIN = "VCUA",
  VCU_EMPLOYEE = "VCUE",
  GPS_ADMIN = "GPSA",
  GPS_EMPLOYEE = "GPSE",
  OBC_ADMIN = "OBCA",
  OBC_EMPLOYEE = "OBCE",
  DCDC_ADMIN = "DCDCA",
  DCDC_EMPLOYEE = "DCDCE",
  BTMS_ADMIN = "BTMSA",
  BTMS_EMPLOYEE = "BTMSE",
  TPMS_ADMIN = "TPMSA",
  TPMS_EMPLOYEE = "TPMSE",
}

export const vehicleTypeObj: Record<string, any> = {
  "2 Wheeler": "2 Wheeler",
  "3 Wheeler": "3 Wheeler",
};

export const connectivityObj: Record<string, any> = {
  "Wi-Fi": "Wi-Fi",
  GSM: "GSM",
  "3G": "3G",
  "4G": "4G",
  "5G": "5G",
};

export enum TICKET_STATUS {
  CLOSED = 6,
  NEW = 1,
  IN_PROGRESS = 2,
  HOLD = 3,
  REOPEN = 4,
  DELETED = 5,
}

export const ticketStatus: Record<string, number> = {
  Closed: 6,
  New: 1,
  "In-Progress": 2,
  Hold: 3,
  Reopen: 4,
  Deleted: 5,
};

export enum VEHICLE_STATE {
  STANDBY = 0,
  ACCESSORY = 16, // key on status
  STARTUP = 32,
  HVON = 48, // tern key and brk but not start
  DRIVE_START_UP = 64,
  DRIVE = 66,
  LIMP_HOME = 67, // reduce driving performance
  CHARGE_START_UP = 80,
  AC_CHARGE = 81,
  DC_CHARGE = 82,
  SHUTDOWN = 96,
  EMERGENCY_SHUTDOWN = 101,
  DISCHARGE = 112,
  SNA = "F", // signal not available
}

export const vehicleState: Record<string, any> = {
  StandBy: 0,
  Accessory: 16, // key on status
  StartUp: 32,
  HVON: 48, // tern key and brk but not start
  "Drive Start Up": 64,
  Drive: 66,
  "Limp Home": 67, // reduce driving performance
  "Charge Start Up": 80,
  "AC Charge": 81,
  "DC Charge": 82,
  Shutdown: 96,
  "Emergency Shutdown": 101,
  Discharge: 112,
  SNA: "F", // signal not available
};

export const BMSState: Record<string, any> = {
  Off: 0,
  Ready: 1,
  Drive: 2,
  Charge: 3,
  Emergency: 4,
  Fault: 5,
  SNA: "F",
};

export const MCUState: Record<string, any> = {
  Off: 0,
  Ready: 1,
  Drive: 2,
  LimpHome: 3,
  Discharge: 4,
  Emergency: 5,
  Fault: 6,
  SNA: "F",
};

export enum FAULT_SEVERITY {
  NONE = 0,
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
  CRITICAL = 4,
  EMERGENCY = 5,
  SNA = "F", // signal not available
}

export const faultComponents: Record<string, string> = {
  BMS: "BMS",
  VCU: "VCU",
  MCU: "MCU",
  EV: "EV",
};

export const faultTypes: Record<string, string> = {
  Component: "Component",
  System: "System",
};

export const faultCategories: Record<string, string> = {
  Alert: "Alert",
  Fault: "Fault",
};

export enum DRIVE_MODE {
  NORMAL = 0,
  ECONOMY = 1,
  PERFORMANCE = 2,
  SNA = "F",
}

export const driveMode: Record<string, any> = {
  Normal: 0,
  Economy: 1,
  Performance: 2,
  SNA: "F",
};

export enum PRND {
  PARK = 0,
  NEUTRAL = 1,
  DRIVE = 2,
  REVERSE = 3,
  SNA = "F",
}

export enum BMS {
  OFF = 0,
  READY = 1,
  DRIVE = 2,
  CHARGE = 3,
  EMERGENCY = 4,
  FAULT = 5,
  SNA = "F",
}

export enum BATTERY_CHEMISTRY {
  NMC = 0,
  LFP = 1,
  NAAIR = 2,
  SOLID_STATE = 3,
  OTHER = 4,
  SNA = "F",
}

export const BatteryChemistry: Record<string, any> = {
  NMC: 0,
  LFP: 1,
  NAAIR: 2,
  "Solid State": 3,
  Other: 4,
  SNA: "F",
};

export enum BATTERY_THERMAL_MANAGEMENT {
  NONE = 0,
  NORMAL_AIR = 1,
  FORCED_AIR = 2,
  LIQUID_COOLED = 3,
  LIQUID_COOLED_WHEAT = 4,
  REFRIGERANT = 5,
  SUBMERGED = 6,
  OTHER = 7,
  SNA = "F",
}

export const batteryThermalManagement: Record<string, any> = {
  None: 0,
  "Normal Air": 1,
  "Forced Air": 2,
  "Liquid Cooled": 3,
  "Liquid Cooled Wheat": 4,
  Refrigerant: 5,
  Submerged: 6,
  Other: 7,
  SNA: "F",
};

export enum MCU_STATE {
  OFF = 0,
  READY = 1,
  DRIVE = 2,
  LIMP_HOME = 3,
  DISCHARGE = 4,
  REFRIGERANT = 5,
  EMERGENCY = 6,
  FAULT = 7,
  SNA = "F",
}

export enum E_MACHINE_TYPE {
  PSM = 0,
  INDUCTION = 1,
  BLDC = 2,
  BRUSHED = 3,
  SRM = 4,
  OTHER = 5,
  SNA = "F",
}

export const machineType: Record<string, any> = {
  PSM: 0,
  Induction: 1,
  BLDC: 2,
  Brushed: 3,
  SRM: 4,
  Other: 5,
  SNA: "F",
};

export const overAllCondition: Record<string, any> = {
  0: "Good Condition",
  1: "Good Condition",
  2: "Moderate",
  3: "Moderate",
  4: "Bad Condition",
  5: "Bad Condition",
  6: "Bad Condition",
  7: "Bad Condition",
};

export const faultLevel: Record<string, any> = {
  Medium: 1,
  High: 2,
  Low: 3,
  Critical: 4,
};

export enum faultSeverityStrings {
  MEDIUM = "Medium",
  HIGH = "High",
  LOW = "Low",
  CRITICAL = "Critical",
}

export enum VEHICLE_ASSIGN_VALUE_TYPE {
  DEALER = "dealer",
  FLEET = "fleet",
  EVOWNER = "evowner",
  DRIVER = "driver",
}

export const severityObj: Record<string, number> = {
  "Very Good": 1,
  Good: 2,
  Average: 3,
  Bad: 4,
  Worst: 5,
};

export enum RULE_TYPE {
  TELEMETRY = 1,
  SHADOW = 2,
  SYSTEM_GENERATED = 3,
  AI = 4,
}

export const ruleTypeObj: Record<string, number> = {
  Telemetry: 1,
  Shadow: 2,
  "System Generated": 3,
  AI: 4,
};

export enum RULE_STATUS {
  INACTIVE = 0,
  ACTIVE = 1,
  DELETED = 2,
}

export const ruleStatusObj: Record<string, number> = {
  Inactive: 0,
  Active: 1,
  Deleted: 2,
};

export enum CONDITION_TYPE {
  AND = 1,
  OR = 2,
}

export enum APPLIES_ON {
  OEM = 1,
  MODEL = 2,
  DEVICE = 3,
}

export enum DELIVERY_METHOD {
  PUSH_NOTIFICATION = 1,
  EMAIL = 2,
  SMS = 4,
  HOOK = 8,
  MQTT_ALERT = 16,
  COMMAND = 32,
}

export enum SEVERTY {
  VERY_GOOD = 1,
  GOOD = 2,
  AVERAGE = 3,
  BAD = 4,
  WORST = 5,
}

export enum RULE_COMPONENT_TYPE {
  VCU = 1,
  BMS = 2,
  MCU = 4,
}

export const ruleComponentType = {
  VCU: 1,
  BMS: 2,
  MCU: 4,
};

export enum ACK_STATUS {
  PENDING = 1,
  SUCCESS = 2,
  FAILED = 3,
}

export enum COMMAND_TYPES {
  DEVICE = 1,
  OTA = 2,
  SYSTEM = 3,
  COMPONENT = 1,
}

export enum COMMAND_FOR_TYPES {
  DEVICE_COMMAND = "DEVICE_COMMAND",
  RULE_COMMAND = "RULE_COMMAND",
  OTA_COMMAND = "OTA_COMMAND",
}

export enum MODEL_CONFIG_TABS {
  INFORMATION = "MODEL_INFORMATION",
  MASTER_DATA = "MODEL_MASTER_DATA",
  TELEMETRY = "MODEL_TELEMETRY",
  CONFIGURATION = "MODEL_CONFIGURATION",
  COMMANDS = "MODEL_COMMANDS",
  FAULTS = "MODEL_FAULTS",
}

export enum MODEL_MASTER_DATA_TABS {
  BMS_STATE = "BMS_STATE",
  MCU_STATE = "MCU_STATE",
  VCU_VEHICLE_STATE = "VCU_VEHICLE_STATE",
  VCU_DRIVE_MODE = "VCU_DRIVE_MODE",
}

export enum MODEL_COMPONENT_TYPES {
  BMS = "bms",
  VCU = "vcu",
  MCU = "mcu",
  EV = "ev",
}

export enum MODEL_CONFIGURATION_STEP_ID {
  INFORMATION = 1,
  VCU_VEHICLE_STATE = 2.1,
  VCU_DRIVE_MODE = 2.3,
  BMS_STATE = 3.1,
  MCU_STATE = 4.1,
  VEHICLE_FAULT_SEVERITY = 5.1,
  COMMANDS = 6,
  FAULTS = 7,
  CONFIGURATION = 8,
  TELEMETRY = 9,
}

export const OEM_TARGET_ROLE: Record<string, any> = {
  ev: [
    { label: "OEM Admin", value: ROLE_SHORT_CODE.OEM_ADMIN },
    { label: "BMS Admin", value: ROLE_SHORT_CODE.BMS_ADMIN },
    { label: "MCU Admin", value: ROLE_SHORT_CODE.MCU_ADMIN },
    { label: "VCU Admin", value: ROLE_SHORT_CODE.VCU_ADMIN },
    { label: "Service Center Admin", value: ROLE_SHORT_CODE.DEALER_ADMIN },
    { label: "Fleet Admin", value: ROLE_SHORT_CODE.FLEET_ADMIN },
    { label: "EV Owner", value: ROLE_SHORT_CODE.EV_OWNER },
    { label: "Driver", value: ROLE_SHORT_CODE.DRIVER },
  ],
  bms: [
    { label: "OEM Admin", value: ROLE_SHORT_CODE.OEM_ADMIN },
    { label: "BMS Admin", value: ROLE_SHORT_CODE.BMS_ADMIN },
    { label: "Service Center Admin", value: ROLE_SHORT_CODE.DEALER_ADMIN },
    { label: "Fleet Admin", value: ROLE_SHORT_CODE.FLEET_ADMIN },
    { label: "EV Owner", value: ROLE_SHORT_CODE.EV_OWNER },
    { label: "Driver", value: ROLE_SHORT_CODE.DRIVER },
  ],
  mcu: [
    { label: "OEM Admin", value: ROLE_SHORT_CODE.OEM_ADMIN },
    { label: "MCU Admin", value: ROLE_SHORT_CODE.MCU_ADMIN },
    { label: "Service Center Admin", value: ROLE_SHORT_CODE.DEALER_ADMIN },
    { label: "Fleet Admin", value: ROLE_SHORT_CODE.FLEET_ADMIN },
    { label: "EV Owner", value: ROLE_SHORT_CODE.EV_OWNER },
    { label: "Driver", value: ROLE_SHORT_CODE.DRIVER },
  ],
  vcu: [
    { label: "OEM Admin", value: ROLE_SHORT_CODE.OEM_ADMIN },
    { label: "VCU Admin", value: ROLE_SHORT_CODE.VCU_ADMIN },
    { label: "Service Center Admin", value: ROLE_SHORT_CODE.DEALER_ADMIN },
    { label: "Fleet Admin", value: ROLE_SHORT_CODE.FLEET_ADMIN },
    { label: "EV Owner", value: ROLE_SHORT_CODE.EV_OWNER },
    { label: "Driver", value: ROLE_SHORT_CODE.DRIVER },
  ],
};

export const COMPONENT_TARGET_ROLE: Record<string, any> = {
  bms: [{ label: "BMS Admin", value: ROLE_SHORT_CODE.BMS_ADMIN }],
  mcu: [{ label: "MCU Admin", value: ROLE_SHORT_CODE.MCU_ADMIN }],
  vcu: [{ label: "VCU Admin", value: ROLE_SHORT_CODE.VCU_ADMIN }],
};

export enum NOTIFICATION_SEVERTY {
  LOW = 3,
  MEDIUM = 1,
  HIGH = 2,
  CRITICAL = 4,
}

export const ROLE_NAMES: Record<string, any> = {
  [ROLE_SHORT_CODE.SUPER_ADMIN]: "Super Admin",
  [ROLE_SHORT_CODE.SUPER_EMPLOYEE]: "Super Employee",
  [ROLE_SHORT_CODE.OEM_ADMIN]: "OEM Admin",
  [ROLE_SHORT_CODE.OEM_EMPLOYEE]: "OEM Employee",
  [ROLE_SHORT_CODE.DRIVER]: "Driver",
  [ROLE_SHORT_CODE.EV_OWNER]: "EV Owner",
  [ROLE_SHORT_CODE.FLEET_ADMIN]: "Fleet Admin",
  [ROLE_SHORT_CODE.FLEET_EMPLOYEE]: "Fleet Employee",
  [ROLE_SHORT_CODE.DEALER_ADMIN]: "Service Center Admin",
  [ROLE_SHORT_CODE.DEALER_EMPLOYEE]: "Service Center Employee",
  [ROLE_SHORT_CODE.BMS_ADMIN]: "BMS Admin",
  [ROLE_SHORT_CODE.BMS_EMPLOYEE]: "BMS Employee",
  [ROLE_SHORT_CODE.MCU_ADMIN]: "MCU Admin",
  [ROLE_SHORT_CODE.MCU_EMPLOYEE]: "MCU Employee",
  [ROLE_SHORT_CODE.VCU_ADMIN]: "VCU Admin",
  [ROLE_SHORT_CODE.VCU_EMPLOYEE]: "VCU Employee",
  [ROLE_SHORT_CODE.GPS_ADMIN]: "GPS Admin",
  [ROLE_SHORT_CODE.GPS_EMPLOYEE]: "GPS Employee",
  [ROLE_SHORT_CODE.OBC_ADMIN]: "OBC Admin",
  [ROLE_SHORT_CODE.OBC_EMPLOYEE]: "OBC Employee",
  [ROLE_SHORT_CODE.DCDC_ADMIN]: "DCDC Admin",
  [ROLE_SHORT_CODE.DCDC_EMPLOYEE]: "DCDC Employee",
  [ROLE_SHORT_CODE.BTMS_ADMIN]: "BTMS Admin",
  [ROLE_SHORT_CODE.BTMS_EMPLOYEE]: "BTMS Employee",
  [ROLE_SHORT_CODE.TPMS_ADMIN]: "TPMS Admin",
  [ROLE_SHORT_CODE.TPMS_EMPLOYEE]: "TPMS Employee",
};

export enum NOTIFICATION_COMPONENT_TYPE {
  LOW = 3,
  MEDIUM = 1,
  HIGH = 2,
  CRITICAL = 4,
}

export const DeliveryMethod = [
  { label: "Push Notification", value: DELIVERY_METHOD.PUSH_NOTIFICATION },
  { label: "Email", value: DELIVERY_METHOD.EMAIL },
  { label: "SMS", value: DELIVERY_METHOD.SMS },
  { label: "Web Hook", value: DELIVERY_METHOD.HOOK },
  { label: "Web Alert", value: DELIVERY_METHOD.MQTT_ALERT },
  { label: "Command", value: DELIVERY_METHOD.COMMAND },
];

export const deliveryMethodObj: Record<string, any> = {
  "Push Notification": DELIVERY_METHOD.PUSH_NOTIFICATION,
  Email: DELIVERY_METHOD.EMAIL,
  SMS: DELIVERY_METHOD.SMS,
  "Web Hook": DELIVERY_METHOD.HOOK,
  "Web Alert": DELIVERY_METHOD.MQTT_ALERT,
  Command: DELIVERY_METHOD.COMMAND,
};

export enum TRIP_STATUS {
  RUNNING_TRIP = 1,
  COMPLETED_TRIP = 2,
  FAULTY_TRIP = 3,
  UNRECOVERABLE_TRIP = 4,
}

export enum BT_CYCLE_STATUS {
  RUNNING_CYCLE = 1,
  COMPLETED_CYCLE = 2,
  FAULTY_CYCLE = 3,
  UNRECOVERABLE_CYCLE = 4,
}

export enum NOTIFICATION_FAULT_SEVERITY_COLORS {
  LOW = "#50C878",
  MEDIUM = "#E2D02A",
  HIGH = "#FF9800",
  CRITICAL = "#DE465F",
}

export enum TRIP_GENERATION_TYPE {
  DISABLE_TRIP = 0,
  MOBILE_CONTROL_TRIP = 1,
  IGNITION_BASED_TRIP = 2,
  BATTERY_GENERATION_TRIP = 3,
}

export const tripGenerationType = [
  { label: "Disable", value: 0 },
  { label: "Mobile Control", value: 1 },
  { label: "Ignition Based", value: 2 },
  { label: "Battery Generation", value: 3 },
];

export enum IS_TRIP_START {
  NO = 0,
  YES = 1,
}

export const defaultDischargeValue = "770";

export const masterStateColors = [
  "#B9C9F3",
  "#94D7F9",
  "#EDDD95",
  "#B1D28A",
  "#CBD08D",
  "#E58E9D",
  "#ACB587",
  "#8EE5D7",
  "#E58EC8",
  "#E5AB8E",
  "#AB8EE5",
  "#8EE5AB",
];

export enum AUDIT_CATEGORY {
  Oem = "oem",
  Employee = "employee",
  AccessManagement = "access_managment",
  RuleManagement = "rule_managment",
  Command = "command",
  VehicleManagement = "vehicle_management",
  VehicleModelManagement = "vehicle_model_management",
  Setting = "setting",
  MyProfile = "my_profile",
  BMSManagement = "bms_managment",
  MCUManagement = "mcu_managment",
  VCUManagement = "vcu_managment",
  DriverManagement = "driver_management",
  EVOwnerManagement = "ev_owner_management",
  FleetManagement = "fleet_management",
  DealerManagement = "dealer_managment",
  FaultManagement = "fault_managment",
  TicketManagement = "ticket_management",
  User = "user_management",
  Download = "download",
  ITCUProducts = "itcu_products",
  ComponentMasterData = "component_master_data",
  Model = "model",
  GEOFENCE = "geo_fencing",
  SimInventory = "sim_inventory",
  LoggerDeviceInventory = "Logger_device_inventory",
  VehicleTrip = "vehicle_trip",
  BarretyCycle = "barrety_cycle",
}

export enum AUDIT_ENTITY {
  OEM = "OEM",
  USER = "User",
  PROFILE = "Profile",
  OEM_REL = "OEM Relation",
  ACCESS = "Access",
  RULE = "Rule",
  // RULE_SUBSCRIBER = 'Rule',
  // RULE_SUBSCRIBER_ENTITY_OWNER = 'RuleSubscriberEntityOwner',
  COMMAND = "Command",
  TELEMETRY = "Telemetry",
  REDIS = "Redis",
  VEHICLE = "Vehicle",
  VEHICLE_MODEL = "Vehicle Model",
  VEHICLE_REL = "Vehicle Relation",
  SETTING = "Settings",
  BMS = "BMS",
  MCU = "MCU",
  VCU = "VCU",
  FLEET = "Fleet",
  DEALER = "Dealer",
  EV_OWNER = "EV Owner",
  DRIVER = "Driver",
  EMPLOYEE = "Employee",
  FLEET_REL = "Fleet Relation",
  DEALER_REL = "Dealer Relation",
  FAULT = "Fault",
  FEEDBACK = "Ticket",
  FEEDBACK_ASSIGNMENT = "Ticket Assignment",
  FEEDBACK_CONVERSION = "Ticket Conversation",
  VEHICLE_VCU = "Vehicle VCU",
  OTP_SESSION = "OTP Session",
  DEVICE_TOKEN = "Device Token",
  ITCU_PRODUCTS = "ITCU Products",
  COMPONENT_MASTER_DATA = "Component Master Data",
  CONFIGURATION_STEPS = "Configuration Steps",
  GEOFENCE = "Geofence",
  GEOFENCE_RULE = "Geofence Rule",
  MODEL = "Model",
  SIM = "SIM Card",
  LOGGER_DEVICE = "Logger Device",
  VEHICLE_TRIP = "Vehicle Trip",
  BARRETY_CYCLE = "Barrety Cycle",
  ANALYSIS_DATA = "Analysis Data",
}

export enum AUDIT_ACTION {
  POST = "POST",
  PATCH = "PATCH",
  CLONE = "CLONE",
  EXPORT = "EXPORT",
  RESYNC = "RESYNC",
  ASSIGN = "ASSIGN",
  BULK_IMPORT = "BULK_IMPORT",
}
export enum AUDIT_ACTION_TITLE {
  POST = "Add",
  PATCH = "Update",
  CLONE = "Clone",
  EXPORT = "Export",
  RESYNC = "Resync",
  ASSIGN = "Assign",
  BULK_IMPORT = "Bulk Import",
}

export enum AUDIT_ACTION_PAST_TITLE {
  POST = "added",
  PATCH = "updated",
  CLONE = "cloned",
  EXPORT = "exported",
  RESYNC = "resynced",
  ASSIGN = "assigned",
  BULK_IMPORT = "bulk imported",
}

export enum FIRMWARE_OTA_UPDATE_STATUS {
  OTA_COMMAND_SENT = 0,
  OTA_IN_PROGRESS = 1,
  OTA_COMPLETED = 2,
  OTA_FAILED = 3,
  DEVICE_OFFLINE = 4,
}

export const otaStatus: Record<string, number> = {
  Pending: 0, // Command Sent
  "In Progress": 1,
  Completed: 2,
  Failed: 3,
  "Device Offline": 4,
};

export enum FIRMWARE_TYPES {
  LOGGER = 0,
  BMS = 1,
  MCU = 2,
  VCU = 3,
}

export const firmwareType: Record<string, number> = {
  Logger: 0,
  BMS: 1,
  MCU: 2,
  VCU: 3,
};

export enum PERMISSION_ACTIONS {
  READ = 1,
  ADD = 2,
  UPDATE = 4,
  DELETE = 8,
  ASSIGN = 16,
  UNASSIGN = 32,
  CLONE = 64,
  EXPORT = 128,
  IMPORT = 256,
  RESYNC = 512,
  COPY_LINK = 1024,
  RESET = 2048,
  CONFIGURE = 4096,
  CHANGE_STATUS = 8192,
  GEOFENCE = 16384,
}

export const permissionActions: Record<string, any> = {
  List: 1,
  Add: 2,
  Update: 4,
  Delete: 8,
  Assign: 16,
  Unassign: 32,
  Clone: 64,
  Export: 128,
  Import: 256,
  Resync: 512,
  "Copy Link": 1024,
  Reset: 2048,
  Configure: 4096,
  "Change Status": 8192,
  Geofence: 16384,
};

export const permissionObj: Record<string, any> = {
  // "/dashboard": [
  //   "db",
  //   "BMS.db",
  //   "MCU.db",
  //   "VCU.db",
  //   "sc.db",
  //   "fleet.db",
  //   "eo.db",
  //   "fleet.driver.db",
  //   "su.db",
  // ],
  "/dashboard": "db",
  "/vehicle-management": "vh.l",
  "/vehicle/[id]": "vh.l.d",
  "/models/configuration": ["vh.m", "BMS.m", "MCU.m", "VCU.m"],
  "/tickets": "t",
  "/ev-owner-management": "eo.l",
  // "/employees": "su",
  "/user-management": ["su", "su.RP", "su.DG"],
  "/fleet-management": "fleet.l",
  "/dealer-management": "sc.l",
  "/access-management": "ac.l",
  "/component-management": "c",
  "/settings": "s",
  "/rule-management": "rule.l",
  "/command-history": "cmd",
  "/notification-history": "rule.h",
  "/bms/master-management": "BMS.l",
  "/bms/details/[id]": "BMS.l.d",
  "/bms/battery-pack-history/[id]": "BMS.l.d.ov.ch",
  "/mcu/master-management": "MCU.l",
  "/mcu/details/[id]": "MCU.l.d",
  "/vcu/master-management": "VCU.l",
  "/vcu/details/[id]": "VCU.l.d",
  "/analysis": "a",
  "/carbon-emission": "ce",
  "/audit": "ah",
  "/profile": "mp",
  "/404": "404",
  "/model-management/ev": "vh.m",
  "/model-management/bms": "BMS.m",
  "/model-management/mcu": "MCU.m",
  "/model-management/vcu": "VCU.m",
  "/geo-fencing": "gr",
  "/geo-fencing/write": "gr",
  "/firmware-release": "FOTA",
  "/firmware-update-history": "FOTA.h",
  "/hardware-log": "hl",
  "/driver/dashboard": "fleet.driver.db",
  "/driver/tickets": "t",
  "/ev-owner/dashboard": "eo.db",
  "/ev-owner/tickets": "t",
  "/fleet/dashboard": "fleet.db",
  "/fleet/driver-management": "fleet.driver",
  // "/fleet/employees": "su",
  "/fleet/vehicle-management": "vh.l",
  "/fleet/vehicle/[id]": "vh.l.d",
  // "/dealer/dashboard": "Dashboard",
  // "/service-center/dashboard": "Dashboard",
  // "/dealer/employees": "su",
  "/dealer/fleet-management": "fleet.l",
  "/dealer/ev-owner-management": "eo.l",
  "/dealer/vehicle-management": "vh.l",
  "/bms/dashboard": "BMS.db",
  "/mcu/dashboard": "MCU.db",
  "/vcu/dashboard": "VCU.db",
  // "/gps/dashboard": "Dashboard",
  // "/obc/dashboard": "Dashboard",
  // "/dcdc/dashboard": "Dashboard",
  // "/btms/dashboard": "Dashboard",
  // "/tpms/dashboard": "Dashboard",
  "/employee/tickets": "t",
  // "/role": "su.RP",
  "/write-role": ["su.RP", "su"],
  // "/group": "su.DG",
  "/write-group": ["su.DG", "su"],
};

export const API_PERMISSION_PATHS = {
  OEM: {
    LIST: "c.p",
    ADD: "c.p",
    UPDATE: "c.p",
    DELETE: "c.p",
  },
  PROFILE: {
    GET: "mp.p",
    UPDATE: "mp.d",
    COMPANY_LOGO: "mp.cl",
    REST_API: "mp.ra",
    CHANGE_PASSWORD: {
      UPDATE: "mp.cp",
    },
    SETTING: {
      UPDATE: "mp.s",
    },
  },
  VEHICLE: {
    LIST: "vh.l.p",
    ADD: "vh.l.p",
    UPDATE: "vh.l.p",
    DELETE: "vh.l.p",
    ASSIGN: "vh.l.p",
    IMPORT: "vh.l.p",
    RESET: "vh.l.p",
    GEOFENCE: "vh.l.p",
    TRIP: {
      LIST: "vh.l.d.ta.p",
      RESYNC: "vh.l.d.ta.p",
    },
    DASHBOARD: {
      INFO: "db.info",
      FAULT_SUMMARY: "db.fs",
      TICKET: "db.t",
      FAULT: "db.raf",
      FOOOTER_CARDS: "db.f",
      MAP: "db.rtmv",
      NOTIFICATION: "db.n",
    },
    DETAILS: {
      OVERVIEW_TAB: "vh.l.d.p",
      TRIP_ANALYSIS_TAB: "vh.l.d.ta.p",
      NOTIFICATION_TAB: "vh.l.d.n.p",
      COMMAND_TAB: "vh.l.d.cmd.p",
      FAULT_TAB: "vh.l.d.f.p",
      ANALYSIS_TAB: "vh.l.d.a.p",
      ATTRIBUTE_TAB: "vh.l.d.attr.p",
      OVER_ALL_INFO: "vh.l.d.ov.oai",
      RECENT_RIPS: "vh.l.d.ov.rt",
      FAULT: "vh.l.d.ov.f",
      STATESTIC: "vh.l.d.ov.s",
      NOTIFICATION: "vh.l.d.ov.n",
      LOCATION_TAB: "vh.l.d.la.p",
      OTA_HISTORY: "vh.l.d.ov.oh",
    },
    MODEL: {
      LIST: "vh.m.p",
      ADD: "vh.m.p",
      UPDATE: "vh.m.p",
      DELETE: "vh.m.p",
      COMPONENT_LIST: "vh.m.p",
      COMMAND: {
        LIST: "vh.m.p",
      },
      TELEMETRY: {
        LIST: "vh.m.p",
        UPDATE: "vh.m.p",
      },
      FAULT: {
        LIST: "vh.m.p",
        ADD: "vh.m.p",
        UPDATE: "vh.m.p",
        DELETE: "vh.m.p",
      },
    },
  },
  BMS: {
    LIST: "BMS.l.p",
    ADD: "BMS.l.p",
    UPDATE: "BMS.l.p",
    DELETE: "BMS.l.p",
    RESET: "BMS.l.p",
    CYCLE: {
      LIST: "BMS.l.d.ca.p",
      RESYNC: "BMS.l.d.ca.p",
    },
    ANALYSIS: {
      LIST: "BMS.l.d.a.p",
      EXPORT: "BMS.l.d.a.p",
    },
    DETAILS: {
      OVERVIEW_TAB: "BMS.l.d.p",
      CYCLE_ANALYSIS_TAB: "BMS.l.d.ca.p",
      NOTIFICATION_TAB: "BMS.l.d.n.p",
      COMMAND_TAB: "BMS.l.d.cmd.p",
      FAULT_TAB: "BMS.l.d.f.p",
      ANALYSIS_TAB: "BMS.l.d.a.p",
      ATTRIBUTE_TAB: "BMS.l.d.attr.p",
      OVER_ALL_INFO: "BMS.l.d.ov.oai",
      STATESTICS: "BMS.l.d.ov.s",
      FAULT: "BMS.l.d.ov.f",
      CELL_INFO: "BMS.l.d.ov.bp",
      CELL_HISTORY: "BMS.l.d.ov.ch.p",
      NOTIFICATION: "BMS.l.d.ov.n",
      LOCATION_TAB: "BMS.l.d.la.p",
      OTA_HISTORY: "BMS.l.d.ov.oh",
    },
    DASHBOARD: {
      READ: "BMS.db.p",
      BATTERY_STATE: "BMS.db.bs",
      FAULT: "BMS.db.af",
      SOC_ANALISYS: "BMS.db.asoc",
      STATESTIC: "BMS.db.s",
      MAP: "BMS.db.map",
      NOTIFICATION: "BMS.db.n",
    },
    MODEL: {
      LIST: "BMS.m.p",
      ADD: "BMS.m.p",
      UPDATE: "BMS.m.p",
      DELETE: "BMS.m.p",
      COMPONENT_LIST: "BMS.m.p",
      COMMAND: {
        LIST: "BMS.m.p",
      },
      TELEMETRY: {
        LIST: "BMS.m.p",
        UPDATE: "BMS.m.p",
      },
      FAULT: {
        LIST: "BMS.m.p",
        ADD: "BMS.m.p",
        UPDATE: "BMS.m.p",
        DELETE: "BMS.m.p",
      },
    },
  },
  MCU: {
    LIST: "MCU.l.p",
    ADD: "MCU.l.p",
    UPDATE: "MCU.l.p",
    DELETE: "MCU.l.p",
    CLOUD_RESET: "MCU.l.p",
    RESET: "MCU.l.p",
    ANALYSIS: {
      EXPORT: "MCU.l.d.a.p",
    },
    DETAILS: {
      OVERVIEW_TAB: "MCU.l.d.p",
      NOTIFICATION_TAB: "MCU.l.d.n.p",
      COMMAND_TAB: "MCU.l.d.cmd.p",
      FAULT_TAB: "MCU.l.d.f.p",
      ANALYSIS_TAB: "MCU.l.d.a.p",
      ATTRIBUTE_TAB: "MCU.l.d.attr.p",
      OVER_ALL_INFO: "MCU.l.d.ov.oai",
      HEALTH: "MCU.l.d.ov.oh",
      STATESTICS: "MCU.l.d.ov.s",
      FAULT: "MCU.l.d.ov.f",
      NOTIFICATION: "MCU.l.d.ov.n",
      LOCATION_TAB: "MCU.l.d.la.p",
      OTA_HISTORY: "MCU.l.d.ov.oh",
    },
    DASHBOARD: {
      FAULT: "MCU.db.f",
      ACTIVE_FAULT: "MCU.db.raf",
      STATESTIC: "MCU.db.s",
      NOTIFICATION: "MCU.db.n",
    },
    MODEL: {
      LIST: "MCU.m.p",
      ADD: "MCU.m.p",
      UPDATE: "MCU.m.p",
      DELETE: "MCU.m.p",
      COMPONENT_LIST: "MCU.m.p",
      COMMAND: {
        LIST: "MCU.m.p",
      },
      TELEMETRY: {
        LIST: "MCU.m.p",
        UPDATE: "MCU.m.p",
      },
      FAULT: {
        LIST: "MCU.m.p",
        ADD: "MCU.m.p",
        UPDATE: "MCU.m.p",
        DELETE: "MCU.m.p",
      },
    },
  },
  VCU: {
    LIST: "VCU.l.p",
    ADD: "VCU.l.p",
    UPDATE: "VCU.l.p",
    DELETE: "VCU.l.p",
    RESET: "VCU.l.p",
    ANALYSIS: {
      EXPORT: "VCU.l.d.a.p",
    },
    MODEL: {
      LIST: "VCU.m.p",
      ADD: "VCU.m.p",
      UPDATE: "VCU.m.p",
      DELETE: "VCU.m.p",
      COMPONENT_LIST: "VCU.m.p",
      COMMAND: {
        LIST: "VCU.m.p",
      },
      TELEMETRY: {
        LIST: "VCU.m.p",
        UPDATE: "VCU.m.p",
      },
      FAULT: {
        LIST: "VCU.m.p",
        ADD: "VCU.m.p",
        UPDATE: "VCU.m.p",
        DELETE: "VCU.m.p",
      },
    },
  },
  COMPONENT_ACCESS: {
    LIST: "ac.l.p",
    UPDATE: "ac.l.p",
  },
  ANALYSIS: {
    LIST: "a.p",
    BMS: "a.bt",
    MCU: "a.mt",
    VCU: "a.vt",
  },
  CARBON_EMISSION: {
    LIST: "ce.p",
  },
  FLEET: {
    LIST: "fleet.l.p",
    ADD: "fleet.l.p",
    UPDATE: "fleet.l.p",
    DELETE: "fleet.l.p",
    DASHBOARD: {
      // INFO: "vh.db.info",
      // FAULT_SUMMARY: "vh.db.fs",
      // TICKET: "vh.db.t",
      // FAULT: "vh.db.f",
      MAP: "fleet.db.map",
    },
    DRIVER: {
      LIST: "fleet.driver.p",
      ADD: "fleet.driver.p",
      UPDATE: "fleet.driver.p",
      DELETE: "fleet.driver.p",
    },
  },
  SERVICE_CENTER: {
    LIST: "sc.l.p",
    ADD: "sc.l.p",
    UPDATE: "sc.l.p",
    DELETE: "sc.l.p",
  },
  EV_OWNER: {
    LIST: "eo.l.p",
    ADD: "eo.l.p",
    UPDATE: "eo.l.p",
    DELETE: "eo.l.p",
    DASHBOARD: {
      MAIN: "eo.db.p",
      FAULT: "vh.db.f",
    },
  },
  SUPER_USER: {
    LIST: "su.p",
    ADD: "su.p",
    UPDATE: "su.p",
    DELETE: "su.p",
  },
  RULE: {
    TELEMETRY: {
      LIST: "rule.l.p",
      ADD: "rule.l.p",
      UPDATE: "rule.l.p",
      DELETE: "rule.l.p",
      DELIVERY_METHOD: "rule.l.dm.mdt",
    },
    GEOFENCE: {
      LIST: "gr.p",
      ADD: "gr.p",
      UPDATE: "gr.p",
      DELETE: "gr.p",
    },
    HISTORY: "rule.h.p",
  },
  SETTING: {
    LIST: "s.p",
    UPDATE: "s.p",
    DELETE: "s.p",
  },
  PRODUCT: {
    LIST: "itcu.p",
  },
  AUDIT: {
    LIST: "ah.p",
  },
  TICKET: {
    LIST: "t.p",
    ADD: "t.p",
    UPDATE: "t.p",
    DELETE: "t.p",
  },
  FOTA: {
    LIST: "FOTA.p",
    OTA: "FOTA.p",
    HISTORY: "FOTA.h.p",
  },
  COMMAND: {
    HISTORY_LIST: "cmd.p",
    SEND_COMMAND: "cmd.sc",
  },
  ROLE_PERMISSION: {
    LIST: "su.RP.p",
    ADD: "su.RP.p",
    UPDATE: "su.RP.p",
    DELETE: "su.RP.p",
    CLONE: "su.RP.p",
  },
  DEVICE_GROUP: {
    LIST: "su.DG.p",
    ADD: "su.DG.p",
    UPDATE: "su.DG.p",
    DELETE: "su.DG.p",
    CLONE: "su.DG.p",
    EV: "su.DG.EV",
    BMS: "su.DG.BMS",
    MCU: "su.DG.MCU",
    VCU: "su.DG.VCU",
  },
  HARDWARE_LOG: "hl.p",
};

export const allRoleJSONPathsJson: Record<string, any> = {
  vh: "Vehicle Management",
  "vh.m": "Model Management",
  "vh.m.p": "Model Permission",
  db: "Dashboard",
  "db.p": "Dashboard Permission",
  "db.op": "Features",
  "db.info": "Vehicle Information",
  "db.fs": "Fault Summary",
  "db.raf": "Recent Active Faults",
  "db.rtmv": "Real-Time Map View of Vehicle",
  "db.f": "Fault (Last 7 Days)",
  "db.t": "Help & Support",
  "db.n": "Notifications",
  "db.fi": "-",
  "vh.l": "Vehicle List",
  "vh.l.p": "Permission",
  "vh.l.op": "Features",
  "vh.l.d": "Details",
  "vh.l.d.p": "Permission",
  // "vh.l.d": "Features",
  "vh.l.d.ov": "Overview",
  "vh.l.d.ov.oai": "Meta Data",
  "vh.l.d.ov.m": "Map",
  "vh.l.d.ov.rt": "Recent trips",
  "vh.l.d.ov.s": "Vehicle Statistics",
  "vh.l.d.ov.f": "Fault (Last 7 Days)",
  "vh.l.d.ov.n": "Notifications",
  "vh.l.d.la": "Location Analysis",
  "vh.l.d.ov.oh": "OTA History",
  "vh.l.d.ta": "Trip Analysis",
  "vh.l.d.ta.p": "Permission",
  "vh.l.d.n": "Notifications",
  "vh.l.d.n.p": "Permission",
  "vh.l.d.cmd": "Commands",
  "vh.l.d.cmd.p": "Permission",
  "vh.l.d.f": "Fault and Alerts",
  "vh.l.d.f.p": "Permission",
  "vh.l.d.a": "Analysis",
  "vh.l.d.a.p": "Permission",
  "vh.l.d.attr": "Attributes",
  "vh.l.d.attr.p": "Permission",
  c: "Component Management",
  "c.p": "Permission",
  BMS: "Battery Management",
  "BMS.m": "Model Management",
  "BMS.m.p": "Permission",
  "BMS.db": "Dashboard",
  "BMS.db.p": "Permission",
  // "BMS.db": "Features",
  "BMS.db.map": "Map",
  "BMS.db.af": "Active Faults",
  "BMS.db.bs": "Battery States",
  "BMS.db.s": "Statistics",
  "BMS.db.asoc": "Analysis by SOC",
  "BMS.db.n": "Notifications",
  "BMS.l": "List",
  "BMS.l.p": "Permission",
  // "BMS.l": "Features",
  "BMS.l.d": "Details",
  "BMS.l.d.p": "Permission",
  // "BMS.l.d": "Features",
  "BMS.l.d.ov": "Overview",
  "BMS.l.d.ov.oai": "Meta Data",
  "BMS.l.d.ov.s": "Live Section",
  "BMS.l.d.ov.m": "Map",
  "BMS.l.d.ov.bp": "Battery Pack",
  "BMS.l.d.ov.rbsc": "Recent Battery State Cycle",
  "BMS.l.d.ov.n": "Notifications",
  "BMS.l.d.ov.ch": "Cell History",
  "BMS.l.d.ov.ch.p": "Permission",
  "BMS.l.d.la": "Location Analysis",
  "BMS.l.d.ov.ch.cv": "Cell Voltage",
  "BMS.l.d.ov.ch.ct": "Cell Temprature",
  "BMS.l.d.ov.oh": "OTA History",
  "BMS.l.d.ca": "Battery State Cycle",
  "BMS.l.d.ca.p": "Permission",
  "BMS.l.d.n": "Notifications",
  "BMS.l.n.p": "Permission",
  "BMS.l.d.cmd": "Commands",
  "BMS.l.cmd.p": "Permission",
  "BMS.l.d.f": "Fault and Alerts",
  "BMS.l.f.p": "Permission",
  "BMS.l.d.a": "Analysis",
  "BMS.l.a.p": "Permission",
  "BMS.l.d.attr": "Attributes",
  "BMS.l.attr.p": "Permission",
  MCU: "Motor Management",
  "MCU.m": "Model Management",
  "MCU.m.p": "Permission",
  "MCU.db": "Dashboard",
  "MCU.db.p": "Permission",
  // "MCU.db": "Features",
  "MCU.db.raf": "Recent Active Faults",
  "MCU.db.f": "Fault (Last 7 Days)",
  "MCU.db.s": "Statistics",
  "MCU.db.n": "Notifications",
  "MCU.l": "List",
  "MCU.l.p": "Permission",
  // "MCU.l": "Features",
  "MCU.l.d": "Details",
  "MCU.l.d.p": "Permission",
  // "MCU.l.d": "Features",
  "MCU.l.d.ov": "Overview",
  "MCU.l.d.ov.oai": "Meta Data",
  "MCU.l.d.ov.m": "Map",
  "MCU.l.d.ov.s": "Live Section",
  "MCU.l.d.ov.f": "Fault (Last 7 Days)",
  "MCU.l.d.ov.n": "Notifications",
  "MCU.l.d.la": "Location Analysis",
  "MCU.l.d.ov.oh": "OTA History",
  "MCU.l.d.n": "Notifications",
  "MCU.l.d.n.p": "Permission",
  "MCU.l.d.cmd": "Commands",
  "MCU.l.d.cmd.p": "Permission",
  "MCU.l.d.f": "Fault and Alerts",
  "MCU.l.d.f.p": "Permission",
  "MCU.l.d.a": "Analysis",
  "MCU.l.d.a.p": "Permission",
  "MCU.l.d.attr": "Attributes",
  "MCU.l.d.attr.p": "Permission",
  VCU: "Vehicle Controller Management",
  "VCU.m": "Model Management",
  "VCU.m.p": "Permission",
  "VCU.l": "List",
  "VCU.l.p": "Permission",
  ac: "Access Control",
  "ac.l": "List",
  "ac.l.p": "Permission",
  // "ac.l": "Features",
  "c.b": "BMS",
  "c.m": "MCU",
  "c.v": "VCU",
  a: "Analysis",
  "a.p": "Permission",
  // "a": "Features",
  "a.bt": "BMS Tab",
  "a.mt": "MCU Tab",
  "a.vt": "VCU Tab",
  ce: "Carbon Emission",
  sc: "Service Center",
  "sc.l": "List",
  "sc.l.p": "Permission",
  "sc.db": "Dashboard",
  "sc.db.p": "Permission",
  // "sc.db": "Features",
  // "sc.db.cs": "cs",
  su: "User Management",
  "su.p": "Permission",
  fleet: "Fleet",
  "fleet.l": "List",
  "fleet.l.p": "Permission",
  "fleet.db": "Dashboard",
  "fleet.db.p": "Permission",
  // "fleet.db": "Features",
  "fleet.db.map": "Map",
  "fleet.driver": "Driver",
  "fleet.driver.p": "Permission",
  eo: "EV Owner",
  "eo.l": "List",
  "eo.l.p": "Permission",
  "eo.db": "Dashboard",
  "eo.db.p": "Permission",
  // "eo.db.op": "Features",
  "eo.db.oai": "Meta Data",
  "eo.db.rt": "Trip Details",
  "eo.db.f": "Faults",
  // "eo.db.dmd": "Drive mode distance",
  rule: "Rule Management",
  "rule.l": "List",
  "rule.l.p": "Permission",
  // "rule.tr.op": "Features",
  "rule.l.dm": "Delivery Method",
  gr: "Geofencing",
  "gr.p": "Permission",
  "rule.h": "History",
  "rule.h.p": "Permission",
  s: "Settings",
  "s.p": "Permission",
  mp: "My Profile",
  "mp.p": "Permission",
  // "mp": "Features",
  "mp.d": "Profile Details",
  "mp.cl": "Company Logo",
  "mp.ra": "REST API",
  "mp.d.p": "Permission",
  "mp.cp": "Change Password",
  "mp.cp.p": "Permission",
  "mp.s": "Setting",
  "mp.s.p": "Permission",
  hl: "Hardware Logs",
  "hl.p": "Permission",
  ah: "Audit",
  "ah.p": "Permission",
  t: "Help & Support",
  "t.p": "Permission",
  cmd: "Command",
  "cmd.h": "History",
  "cmd.h.p": "Permission",
  // "cmd": "Features",
  "cmd.sc": "Send Command",
  itcu: "Product",
  "itcu.p": "Permission",
  FOTA: "FOTA",
  "FOTA.p": "Permission",
  // "FOTA": "Features",
  "FOTA.ota": "OTA",
  "FOTA.h": "History",
  "FOTA.h.p": "Permission",
  "su.RP": "Role & Permission",
  "su.RP.p": "Permission",
  "su.DG": "Device Group",
  "su.DG.EV": "EV",
  "su.DG.BMS": "BMS",
  "su.DG.MCU": "MCU",
  "su.DG.VCU": "VCU",
};

export enum SLEEP_MODE {
  OFF = 0,
  DEEP_SLEEP = 1,
  ONLINE_DEEP_SLEEP = 2,
  ON_DEMAND = 3,
}

export const sidebarPageArr = [
  "/dashboard",
  "/bms/dashboard",
  "/mcu/dashboard",
  "/vcu/dashboard",
  "/driver/dashboard",
  "/fleet/dashboard",
  "/ev-owner/dashboard",
  "/model-management/ev",
  "/model-management/bms",
  "/model-management/mcu",
  "/model-management/vcu",
  "/vehicle-management",
  "/bms/master-management",
  "/mcu/master-management",
  "/vcu/master-management",
  "/component-management",
  "/access-management",
  "/fleet-management",
  "/dealer-management",
  "/ev-owner-management",
  "/fleet/driver-management",
  "/user-management",
  "/analysis",
  "/rule-management",
  "/notification-history",
  "/command-history",
  "/audit",
  "/firmware-release",
  "/hardware-log",
  "/tickets",
  "/settings",
  "/profile",
  "/geo-fencing",
];

export const routeNames2: Record<string, any> = {
  "/dashboard": "Dashboard",
  "/dealer/dashboard": "Dashboard",
  "/bms/dashboard": "Dashboard",
  "/mcu/dashboard": "Dashboard",
  "/vcu/dashboard": "Dashboard",
  "/driver/dashboard": "Dashboard",
  "/ev-owner/dashboard": "Dashboard",
  "/fleet/dashboard": "Dashboard",
  "/old-dashboard": "Old dashboard",
  "/vehicle-management": "Vehicle Management",
  "/fleet/vehicle-management": "Vehicle Management",
  "/dealer/vehicle-management": "Vehicle Management",
  "/vehicle/[id]": "Vehicle Details",
  "/old-vehicle/[id]": "Old vehicle Details",
  "/models/configuration": "Model Configuration",
  "/ev-owner-management": "EV Owner Management",
  "/fleet-management": "Fleet Management",
  "/dealer-management": "Dealer Management",
  "/access-management": "Access Management",
  "/component-management": "Component Management",
  "/settings": "Settings",
  "/oem-access": "OEM Access",
  "/rule-management": "Rule Management",
  "/command-history": "Command",
  "/notification-history": "Notification",
  "/bms/master-management": "BMS Management",
  "/bms/details/[id]": "BMS Details",
  "/bms/battery-pack-history/[id]": "Battery Pack History",
  "/mcu/master-management": "MCU Management",
  "/mcu/details/[id]": "MCU Details",
  "/vcu/master-management": "VCU Management",
  "/vcu/details/[id]": "VCU Details",
  "/analysis": "Analysis",
  "/audit": "Audit",
  "/profile": "Profile",
  "/404": "404",
  "/403": "403",
  "/model-management/ev": "EV Model Management",
  "/model-management/bms": "BMS Model Management",
  "/model-management/mcu": "MCU Model Management",
  "/model-management/vcu": "VCU Model Management",
  "/geo-fencing": "Geofencing",
  "/geo-fencing/write": "Geofencing",
  "/firmware-release": "FOTA",
  "/firmware-update-history": "Device OTA history",
  "/hardware-log": "Hardware Log",
  "/fleet/driver-management": "Driver Management",
  "/fleet/vehicle/[id]": "vehicle Details",
  "/dealer/fleet-management": "Fleet Management",
  "/dealer/ev-owner-management": "EV Owner Management",
  "/employee/tickets": "Tickets",
  "/driver/tickets": "Tickets",
  "/ev-owner/tickets": "Tickets",
  "/tickets": "Tickets",
  "/user-management": "User Management",
  "/write-role": "Role Permission",
  "/write-group": "Device Group",
  "/carbon-emission": "Carbon Emission",
};

export enum LOCATION_ANALYSIS_TYPE {
  MOST_FREQUENT = 3,
  HOME = 1,
  OFFICE = 2,
}
